import type { PdpEcommerceBlockProductDetailsProps } from '@design-system/components/Pdp/EcommerceBlock/Product/PdpEcommerceBlockProductDetails.props'
import type { ReserveShop } from '@design-system/components/Pdp/EcommerceBlock/ReserveInStore/PdpEcommerceBlockReserveInStoreShopAddress.props'
import type { ReserveFormPersonalInfo } from '@design-system/components/Pdp/EcommerceBlock/ReserveInStore/PdpEcommerceBlockReserveInStoreStepTwo.props'
import type { BookAndReserveRequest } from '../schemas/bookAndReserveSchema'
import type { BookAnAppointmentForm } from './useBookAnAppointment'
import type { ShopObject } from '../schemas/bookAnAppointmentShopsSchema'

export const useBookAndReserve = async () => {
  const { country, language } = useRouteHelper()
  const { getCurrencySymbolFromCountryCode, currentCurrency } = useCurrency()
  const { formatPriceByLocale } = useFormatPrice()
  const { customer } = await useCustomer()

  const parsedSalesforceId = () => {
    if (customer && customer.value.id) return customer.value.id.split('|')[1]
    return ''
  }

  const parsedCustomerLanguage = () => {
    if (customer && customer.value.language && customer.value.language !== '')
      return customer.value.language
    return language
  }

  const parsedCustomerCountry = () => {
    if (customer && customer.value.country && customer.value.country !== '')
      return customer.value.country
    return country
  }

  const parsedProductCurrency = () => {
    const currencyFromCountry = getCurrencySymbolFromCountryCode(country)
    if (currencyFromCountry) return currencyFromCountry.symbol as string
    return ''
  }

  const parsedProductImage = (productImage: string) => {
    return `https://assets.armani.com/image/upload/${productImage}`
  }

  const fetchReserve = async (
    shop: ReserveShop,
    formPersonalInfo: ReserveFormPersonalInfo,
    product: PdpEcommerceBlockProductDetailsProps,
    productImage: string,
    idProduct: string,
    selectedSize: string,
    email: string
  ) => {
    const body: BookAndReserveRequest = {
      emailType: 'reserve_in_store',
      storeName: shop.name,
      storeEmail: email,
      storeId: shop.code,
      productCode: idProduct,
      productName: product.title,
      productPrice: formatPriceByLocale(currentCurrency.value, product.price),
      productCurrency: parsedProductCurrency(),
      productImage: parsedProductImage(productImage),
      size: selectedSize,
      salesforceId: parsedSalesforceId(),
      customerCountry: parsedCustomerCountry(),
      customerTitle: formPersonalInfo.gender,
      customerName: formPersonalInfo.firstName,
      customerLastname: formPersonalInfo.lastName,
      customerEmail: formPersonalInfo.email,
      customerPhonePrefix: formPersonalInfo.phonePrefix,
      customerPhone: formPersonalInfo.phoneNumber,
      customerMessage: formPersonalInfo.message,
      customerLanguage: parsedCustomerLanguage(),
    }
    return $fetch('/api/bookAndReserve/', {
      method: 'POST',
      body,
    })
  }

  const fetchBookAppontment = async (
    shop: ShopObject,
    bookAppointmentForm: BookAnAppointmentForm
  ) => {
    const body: BookAndReserveRequest = {
      emailType: 'book_an_appointment',
      storeName: shop.name,
      storeEmail: shop.emails?.[0] || '',
      storeId: shop.c_siteID,
      // storeNumber: shop.number,
      productCode: '',
      productName: '',
      productPrice: '',
      productCurrency: parsedProductCurrency(),
      productImage: '',
      size: '',
      salesforceId: parsedSalesforceId(),
      customerCountry: parsedCustomerCountry(),
      customerTitle: '',
      customerName: bookAppointmentForm.firstName,
      customerLastname: bookAppointmentForm.lastName,
      customerEmail: bookAppointmentForm.email,
      customerPhonePrefix: bookAppointmentForm.phonePrefix,
      customerPhone: bookAppointmentForm.phoneNumber,
      customerMessage: bookAppointmentForm.message,
      customerLanguage: parsedCustomerLanguage(),
    }
    return $fetch('/api/bookAndReserve/', {
      method: 'POST',
      body,
    })
  }

  return {
    fetchReserve,
    fetchBookAppontment,
  }
}
